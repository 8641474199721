const BankAccount = [
    {
        value: "1",
        text: "Sí, ahí recibo mis ventas"
    },
    {
        value: "2",
        text: "Sí, pero no la uso mucho"
    },
    {
        value: "0",
        text: "No tengo"
    }
];

export default BankAccount;
