import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import "../../../css/header.css";
import firstImage from "../../../assets/img/carousel/Banners_Home_DC_Mesa de trabajo 1.webp";
import firstImageMobie from "../../../assets/img/carousel/Banners_Home_DC_MÓVIL_1.webp";

const getHeight = () => {
  let isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;
  let isTablet = window.matchMedia(
    "only screen and (max-width: 1024px)"
  ).matches;
  let isDesktop = window.matchMedia(
    "only screen and (min-width: 1025px)"
  ).matches;

  if (isMobile) {
    return 1;
  } else if (isTablet) {
    if (window.innerWidth < 950 && window.innerHeight < 767) {
      return 1;
    } else {
      return 2;
    }
  } else if (isDesktop) {
    if (window.innerWidth > 1080) {
      return 0;
    } else {
      return 2;
    }
  }
};

const Header = (props) => {
  const [versionImage, setVersionImage] = useState(props.estado);
  const [height, setHeight] = useState(getHeight());

  useEffect(() => {
    switch (height) {
      case 0:
        setVersionImage(0);
        break;
      case 1:
        props.estado === 0 ? setVersionImage(0) : setVersionImage(1);
        break;
      case 2:
        setVersionImage(0);
        break;
      default:
        setVersionImage(0);
        break;
    }
    setHeight(getHeight());
  }, [height, props.estado]);

  const img = [firstImage, firstImageMobie];

  const widthI = versionImage === 0 ? "3840" : "1920";
  const heightI = versionImage === 0 ? "2160" : "1080";

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const formHb = "https://share.hsforms.com/1K4EneRLMSAOjIMj-pdh4og2y96v";

  return (
    <div className="container-fluid alianza_div">
      <img
        src={img[versionImage]}
        alt="alianza"
        width={widthI}
        height={heightI}
        className="alianza-banner"
      />
      <Button
        onClick={() => openInNewTab(formHb)}
        className="header-button fz18 bluePrimary"
      >
        comenzar solicitud
      </Button>
    </div>
  );
};

export default Header;
