const EmployeesNumber = [
    {
        value: "1-10",
        text: "De 1 a 10"
    },
    {
        value: "11-30",
        text: "De 11 a 30"
    },
    {
        value: "31-100",
        text: "De 31 a 100"
    },
    {
        value: "+100",
        text: "Más de 100"
    }
];

export default EmployeesNumber;
