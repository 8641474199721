import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import '../../../css/products.css';

import web from '../../../assets/img/carousel-products/productos_web-01.webp';
import web1 from '../../../assets/img/carousel-products/productos_web-02.webp';


 import movil from '../../../assets/img/carousel-products/Movil/vivienda.webp';
 import movi1 from '../../../assets/img/carousel-products/Movil/construccion.webp';
 import movi2 from '../../../assets/img/carousel-products/Movil/liquidez.webp';
 import movi3 from '../../../assets/img/carousel-products/Movil/pasivos.webp';
 import movi4 from '../../../assets/img/carousel-products/Movil/terreno+compra.webp';
 import movi5 from '../../../assets/img/carousel-products/Movil/terreno.webp';


 const imagesW = [
	[web, web1],
 ];
 const imagesM = [
	[movil, movi1, movi2, movi3, movi4, movi5],
 ];

const AlliesCarousel = (props) => {
    
    const [alliesImages, setAlliesImages] = useState(imagesW);
    const [versionImage, setVersionImage] = useState(0);
	const [indexImage, setIndexImage] = useState(0);

	const handleSelect = (selectedIndex, e) => {
		setIndexImage(selectedIndex);
	};

    useEffect(() => {
        if(versionImage === 0){//Web
            setAlliesImages(imagesW);
        }
        else{//Movil
            setAlliesImages(imagesM);

            // setAlliesImages(images);//Si quieres el resto
        }
    }, [versionImage]);

    useEffect(() => {
        setVersionImage(props.estado.estado);
    } , [props.estado.estado]);

    return(
        <Carousel className="mb-10 pb-40 pt-40" activeIndex={indexImage} onSelect={handleSelect}>
            {
                alliesImages[0].map((image, index) => {
                    return(
                        <Carousel.Item key={index}>
                            <img className="d-block w-100" src={image} alt="slide" />
                        </Carousel.Item>
                    )
                }
                )

            }
        </Carousel>
    );
}

export default AlliesCarousel;