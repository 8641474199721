import React, { useState, useLayoutEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import isAuthenticated from '../../utils/isAuthenticated'
import logo  from '../../assets/img/logos_casa_color.png'
import '../../css/nav-bar.css'
import Whatsapp from '../../assets/img/redes_sociales/whatsapp.png';
const NavBar = props => {
	const [isOpen, setIsOpen] = useState(false);
	const [showHome, setShowHome] = useState(false);
	const [showEstatus, setShowEstatus] = useState(false);

	let user = null;
	let idClient = null;
	let finishAppliance = false;
	let appliance = null;

	const partners = [
		'pagaloop',
		'impulsomx',
		'visoor',
		'oficinanoreste',
		'dg-impulsando',
		'se1'
];

	useLayoutEffect(() => {
		if (isAuthenticated()){
			user = JSON.parse(sessionStorage.getItem("user"));
			idClient = user.idClient;
			if (idClient.hasOwnProperty("type")){
				setShowEstatus(true);
			}
			if (idClient.appliance.length > 0){
				appliance = idClient.appliance[idClient.appliance.length - 1];
				finishAppliance = appliance.status;
	
				if(user.idClient.appliance[0].hasOwnProperty("idGeneralInfo") && user.idClient.appliance[0].hasOwnProperty("idComercialInfo") && user.idClient.appliance[0].hasOwnProperty("idDocuments")){
					setShowHome(true);
				}
			}

		}
	})
	
	const history = useHistory();
	const location = useLocation();
	const close = () => setIsOpen(false);
	let classDefault = "hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg";
	let classDefaultLink = "text-center coolvetica fz16 gray50 heigth-45 nav-btn d-flex";
	const [showWhatsAppBtn] = useState(partners.filter(partner => partner == 'impulsomx').includes(location.pathname.toLowerCase().split("/")[2]))
	// const [showWhatsAppBtn] = useState(partners.filter(partner => partner !== 'visoor').includes(location.pathname.toLowerCase().split("/")[2]))
	
	//console.log(location.pathname.split("/")[2].toLowerCase());

	return (
		<div id="navbar-dp" className="pos-f-t">
		 <Navbar collapseOnSelect className="nav-bar" expand="lg" bg="light" expanded={isOpen}>
		 	<Navbar.Brand href = {partners.includes(location.pathname.toLowerCase().split("/")[2]) ||  location.pathname === '/brokersCP' || location.pathname.split("/").length > 3 ?  "#" : "/"}><img src={logo} alt="Distrito Logo" className="nav-logo"/></Navbar.Brand>
			 {(location.pathname != '/brokers'&&  location.pathname !== '/brokersCP' && !/^((\/registroexitoso)(\/[a-z]+))/gi.test(location.pathname)) && !partners.includes(location.pathname.toLowerCase().split("/")[2]) &&
				<>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsOpen(!isOpen)}/>
					{isAuthenticated() === false &&
						<Navbar.Collapse>
							<Nav className="ml-auto ">
								<Nav.Link onClick={close} as={NavLink} to="/brokers" id="quieroSerBorker" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">quiero ser broker</Nav.Link>
								{/* <Nav.Link onClick={close} as={NavLink} to="/solicitudBrokers" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">soy broker</Nav.Link> */}
								<Nav.Link onClick={close} as={NavLink} to="/nuestros-aliados" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">aliados</Nav.Link>
								<Nav.Link onClick={close} href="https://share.hsforms.com/1kKDWR6-hQ1WXTwre_5c5gg2y96v" target="_blank" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">soy broker</Nav.Link>
								<Nav.Link onClick={close} href="https://distritopyme.com/" target="_blank" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg mr-2" id='PYMEDP'>distrito pyme</Nav.Link>
								{/* <Nav.Link onClick={close} href="https://distritopyme.com/" target="_blank" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg link link--arrowed">
								<svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="32" viewBox="0 0 32 32">
      <g fill="none" stroke="#2175FF" stroke-width="1.5" stroke-linejoin="round" stroke-miterlimit="10">
        <circle class="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
		<text class="arrow-icon--arrow" x="16" y="20" fill="#2175FF" font-size="12px" font-family="Metropolis-Regular, Metropolis" text-anchor="middle">PYMES</text>
      </g>
    </svg>
								</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="/#simulador" id="ymb-dp-nav-simulator" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">Simulador</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="/#howWorks" id="ymb-dp-nav-howWorks" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">¿Cómo funciona?</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="/#about" id="ymb-dp-nav-about" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">Nosotros</Nav.Link> */}
								{/* <Nav.Link  onClick={close} href="https://distritopyme.com.mx/" id="ymb-dp-nav-howWorks" target="_blank" className="hvr-underline-from-left  text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">Blog</Nav.Link> */}
								{location.pathname != '/brokers' && location.pathname != '/solicitud_enviada_brokers' && location.pathname != '/alianza' && 
									<>
										{/* <Nav.Link  onClick={close} as={NavLink} to="/login" id="ymb-dp-nav-register" className="text-center fz16 blackNav nav-btn heigth-45 solicita-butto metropolisReg">inicia sesión</Nav.Link> */}
										<Nav.Link  onClick={close} href='https://share.hsforms.com/1K4EneRLMSAOjIMj-pdh4og2y96v' target="_blank" id="ymb-dp-nav-login" className="text-center fz16 nav-btn nav-btn-rad primary heigth-95 w-360 ml-auto mr-auto" style={{textDecoration: "none"}}>comenzar solicitud</Nav.Link>
									</>
								}
							</Nav>
						</Navbar.Collapse>
					}
					{isAuthenticated() === true && 
					<Navbar.Collapse id="basic-navbar-nav">	
						<Nav className="ml-auto">
							{/* {  
								finishAppliance && 
								<Link onClick={close} to={`/credito/solicitud/${appliance._id}`} className={ (props.url === 'credito') ? classDefault+' nav_bar_active': classDefault}>Mi crédito</Link>
							}
							{
								!finishAppliance && 
								<Link onClick={close} to="/credito/" className={ (props.url === 'credito') ? classDefault+' nav_bar_active': classDefault}>Mi crédito</Link>
							} */}
							{
								showEstatus &&
								<Link onClick={close} to="/estatus_solicitud" className={ (props.url === 'estatus_solicitud') ? classDefault+' nav_bar_active': classDefault}>Estatus de Solicitud</Link>
							}
							{ 
								showHome &&
								<Link onClick={close} to="/home" className={ (props.url === 'home') ? classDefault+' nav_bar_active': classDefault}>Mi cuenta</Link>
							}
							<Nav.Link onClick={close} href="/" className="text-center blackBlue heigth-45 metropolisReg"> <Button className="logout fz-12" onClick={() => {
								sessionStorage.clear();
								window.location.reload()
							}}>Cerrar sesión</Button></Nav.Link>
						</Nav>
					</Navbar.Collapse>
					}
				</>
			}
			
		 </Navbar>
		 
		 {
				showWhatsAppBtn &&

				<div className="nav-bar-icon-whatsapp" style={{ zIndex: "10001" }}>
							<a href="https://api.whatsapp.com/send?phone=525539480196&text=Hola%2c%20%c2%bfpodr%c3%adan%20ayudarme%3f&source=&data=" target="_blank" rel="noopener noreferrer"><i><img src={Whatsapp} alt="Whatsapp Distrito Pyme" className="nav-bar-icon-whatsapp"/></i></a>
				</div>
			}
		</div>
	)
}

export default NavBar
