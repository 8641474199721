const EmpresarialCreditCard = [
    {
        value: "1",
        text: "Sí"
    },
    {
        value: "0",
        text: "No"
    },
    {
        value: "2",
        text: "Me interesa obtener una"
    }
];

export default EmpresarialCreditCard;
