// SIMULATOR

export const UPDATE_AMOUNT = 'UPDATE_AMOUNT'
export const UPDATE_AMOUNT_SUCCESS = 'UPDATE_AMOUNT_SUCCESS'
export const UPDATE_AMOUNT_ERROR = 'UPDATE_AMOUNT_ERROR'

export const UPDATE_STEP = 'UPDATE_STEP'
export const UPDATE_STEP_SUCCESS = 'UPDATE_STEP_SUCCESS'
export const UPDATE_STEP_ERROR = 'UPDATE_STEP_ERROR'

export const UPDATE_TERM = 'UPDATE_TERM' 
export const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS'
export const UPDATE_TERM_ERROR = 'UPDATE_TERM_ERROR'

export const UPDATE_CONT = 'UPDATE_CONT'
export const UPDATE_CONT_SUCCESS = 'UPDATE_CONT_SUCCESS'
export const UPDATE_CONT_ERROR = 'UPDATE_CONT_ERROR'

export const SET_NEW_SIMULATION = 'SET_NEW_SIMULATION'
export const SET_NEW_SIMULATION_SUCCESS = 'SET_NEW_SIMULATION_SUCCESS'
export const SET_NEW_SIMULATION_ERROR = 'SET_NEW_SIMULATION_ERROR'